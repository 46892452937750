import { FC, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import phoneIcon from 'assets/phone.png'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ToolTip from 'react-portal-tooltip'
import Rating from '@mui/material/Rating'
import get from 'lodash/get'
import location from 'assets/location.png'
import { useSelector } from 'react-redux'
import { locationApiSelector } from 'ducks/locationApi'
import { apiServiceDetail } from 'services/apiDetailBusiness'
import { Carousel } from 'react-responsive-carousel'
import defaultImage from '../../../assets/captura.png'
import iconCopy from '../../../assets/shareCopyBrown.png'
import iconPrueba from '../../../assets/iconPrueba.png'
import { isOpen, isOpenYelp, openOrClosed, openOrClosedYelp } from '../BussinesCardDetailComponent/getHours'
import { googleMapsContext } from '../GoogleMapsComponent/context'
import { getDistanceFromLatLng } from '../BussinesCardDetailComponent/getDistanceFromLatLng'
import { Data } from '../BussinesCardDetailComponent/details'
import * as S from './styled'
import { CarouselContainer } from './styled'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import map from 'assets/map404.png'

const MySwal = withReactContent(Swal)
interface Props {
  scrollId?: string
  logoUrl: string
  slug2: string
  name: string
  distance: number
  rating: number
  openNow: boolean
  openNowYelp: boolean
  googleHours: OpeningHours
  placeId: string
  yelpHours: Hour[]
  phone: string
  googleLink: string
  googleId: string
  uniqueKey: string
  address: string
  city: string
  state: string
  lat: number
  lng: number
  goToBussinesDetail: (slug: string) => void
  imageUrl: any
  style: any
  hide_address: number
}

interface OpeningHours {
  open_now: boolean
  periods: Period[]
  weekday_text: string[]
}
interface Period {
  close: Close
  open: Close
}

interface Close {
  day: number
  time: string
}

interface Hour {
  open: Open[]
  hours_type: string
  is_open_now: boolean
}
interface Open {
  is_overnight: boolean
  start: string
  end: string
  day: number
}

export const CardComponent: FC<Props> = ({
  logoUrl,
  slug2,
  name,
  distance,
  rating,
  openNowYelp,
  googleHours,
  yelpHours,
  phone,
  googleLink,
  googleId,
  placeId,
  address,
  city,
  state,
  lat,
  lng,
  uniqueKey,
  goToBussinesDetail,
  imageUrl,
  style,
  scrollId,
  hide_address,
}) => {
  const [, setMapState] = useContext(googleMapsContext)
  const [toolTip, setTooltip] = useState({ cellPhone: false, address: false })

  const showTooltipByProp = (prop: string) => () => {
    setTooltip({ ...toolTip, [prop]: true })
  }

  const hideTooltipByProp = (prop: string) => () => {
    setTooltip({ ...toolTip, [prop]: false })
  }

  const showLocationOnMap = () => () => {
    setMapState((v) => ({ ...v, center: { lat, lng }, markerHoverId: slug2 }))
  }

  const [isMobile2, setIsMobile] = useState(false)

  useEffect(() => {
    if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      setIsMobile(true)
    }
  }, [])

  const locator = useSelector(locationApiSelector)

  const locationUser = () => {
    let userLocation = { lat: 0, lng: 0 }
    if (locator?.location?.location?.locationLat) {
      userLocation = { lat: locator.location.location.locationLat, lng: locator.location.location.locationLng }
    } else {
      userLocation = { lat: locator?.api?.api?.apiLat, lng: locator?.api?.api?.apiLng }
    }
    return userLocation
  }

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const tzlookup = require('tz-lookup')

  const timezone = (latitude: number, longitude: number) => {
    const zone = tzlookup(latitude, longitude)
    return zone
  }
  return (
    <S.CardContainer style={style} className={scrollId === slug2 ? 'scaleUp' : ''}>
      <S.Card>
        <S.InfoSection>
          <S.TopSection>
            <S.IconCard>
              <Link to={`/business/${encodeURIComponent(slug2)}`}>
                {logoUrl ? <S.Icon src={logoUrl} alt="icon" /> : <S.Icon src={iconPrueba} alt="icon" />}
              </Link>
            </S.IconCard>
            <S.PrincipalInfo>
              <Link to={`/business/${encodeURIComponent(slug2)}`}>
                <S.Name onMouseEnter={showLocationOnMap()}>{name} </S.Name>
              </Link>
              <S.Distance>
                {getDistanceFromLatLng(lat, lng, locationUser().lat, locationUser().lng)} miles away
              </S.Distance>
              {!isMobile2 ? (
                <div style={{ alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
                  <Rating name="read-only" value={rating} readOnly />
                  <S.RatingValueNumber>{rating}</S.RatingValueNumber>
                </div>
              ) : (
                <div>
                  {googleHours && isOpen(googleHours?.periods, timezone(lat, lng)) ? (
                    <S.Schedule>Open now - {openOrClosed(googleHours.periods)}</S.Schedule>
                  ) : googleHours && !isOpen(googleHours?.periods, timezone(lat, lng)) ? (
                    <S.ScheduleClosed>Closed</S.ScheduleClosed>
                  ) : yelpHours && isOpenYelp(yelpHours[0].open, timezone(lat, lng)) ? (
                    <S.Schedule>Open now - {openOrClosedYelp(yelpHours[0].open)}</S.Schedule>
                  ) : yelpHours && !isOpenYelp(yelpHours[0].open, timezone(lat, lng)) ? (
                    <S.ScheduleClosed>Closed</S.ScheduleClosed>
                  ) : null}
                </div>
              )}
            </S.PrincipalInfo>
          </S.TopSection>
          {googleHours && isOpen(googleHours?.periods, timezone(lat, lng)) && !isMobile2 ? (
            <S.Schedule>Open now {openOrClosed(googleHours.periods)}</S.Schedule>
          ) : googleHours && !isOpen(googleHours?.periods, timezone(lat, lng)) && !isMobile2 ? (
            <S.ScheduleClosed>Closed</S.ScheduleClosed>
          ) : yelpHours && isOpenYelp(yelpHours[0].open, timezone(lat, lng)) && !isMobile2 ? (
            <S.Schedule>Open now {openOrClosedYelp(yelpHours[0].open)}</S.Schedule>
          ) : yelpHours && !isOpenYelp(yelpHours[0].open, timezone(lat, lng)) && !isMobile2 ? (
            <S.ScheduleClosed>Closed</S.ScheduleClosed>
          ) : null}
          <S.ButtonSection>
            {phone ? (
              <>
                <S.CallButton
                  href={`tel:${phone}`}
                  data-tip
                  id={`phoneCallSearch-${uniqueKey}`}
                  onMouseEnter={showTooltipByProp('cellPhone')}
                  onMouseLeave={hideTooltipByProp('cellPhone')}
                >
                  <S.IconCall src={phoneIcon} />
                  Call
                </S.CallButton>
                <ToolTip
                  style={{
                    style: {
                      color: '#AD9F86',
                      border: '#8F7E60 1px solid',
                      borderRadius: '8px',
                      padding: '0.5rem',
                    },
                    arrowStyle: {
                      borderColor: '#8F7E60',
                    },
                  }}
                  active={toolTip.cellPhone}
                  position="top"
                  arrow="center"
                  parent={`#phoneCallSearch-${uniqueKey}`}
                >
                  {phone}
                  <S.Line />
                  <S.IconCopy
                    onClick={() => {
                      navigator.clipboard.writeText(phone)
                      MySwal.fire({
                        title: 'Copied to clipboard',
                        showConfirmButton: false,
                        width: 400,
                        background: '#fffbf4',
                        timer: 1000,
                        customClass: {
                          container: 'swal-container',
                          title: 'swal-title',
                        },
                      })
                    }}
                    src={iconCopy}
                  />
                </ToolTip>
              </>
            ) : (
              <S.CallButtonDisabled>
                <S.IconCall src={phoneIcon} />
                Call
              </S.CallButtonDisabled>
            )}
            {googleId ? (
              <>
                {hide_address === 0 ? (
                  <S.DirectionButton
                    href={`https://www.google.com/maps/dir/?api=1&destination=1&destination_place_id=${googleId}`}
                    target="_blank"
                    data-tip
                    onMouseEnter={showTooltipByProp('address')}
                    onMouseLeave={hideTooltipByProp('address')}
                    id={`directions-street-${uniqueKey}`}
                  >
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButton>
                ) : (
                  <S.DirectionButtonDisabled>
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButtonDisabled>
                )}
                <ToolTip
                  style={{
                    style: {
                      color: '#AD9F86',
                      border: '#8F7E60 1px solid',
                      borderRadius: '8px',
                      padding: '0.5rem',
                    },
                    arrowStyle: {
                      borderColor: '#8F7E60',
                    },
                  }}
                  active={toolTip.address}
                  position="top"
                  arrow="center"
                  parent={`#directions-street-${uniqueKey}`}
                >
                  {address}
                </ToolTip>
              </>
            ) : placeId ? (
              <>
                {hide_address === 0 ? (
                  <S.DirectionButton
                    href={`https://www.google.com/maps/dir/?api=1&destination=1&destination_place_id=${placeId}`}
                    target="_blank"
                    data-tip
                    onMouseEnter={showTooltipByProp('address')}
                    onMouseLeave={hideTooltipByProp('address')}
                    id={`directions-street-${uniqueKey}`}
                  >
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButton>
                ) : (
                  <S.DirectionButtonDisabled>
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButtonDisabled>
                )}
                <ToolTip
                  style={{
                    style: {
                      color: '#AD9F86',
                      border: '#8F7E60 1px solid',
                      borderRadius: '8px',
                      padding: '0.5rem',
                    },
                    arrowStyle: {
                      borderColor: '#8F7E60',
                    },
                  }}
                  active={toolTip.address}
                  position="top"
                  arrow="center"
                  parent={`#directions-street-${uniqueKey}`}
                >
                  {address}
                </ToolTip>
              </>
            ) : address.length > 1 && city.length > 1 && state.length > 1 ? (
              <>
                {hide_address === 0 ? (
                  <S.DirectionButton
                    href={`https://www.google.com/maps/dir/?api=1&destination=${address.replace(
                      / /g,
                      '+'
                    )}+${city.replace(/ /g, '+')}+${state}`}
                    target="_blank"
                    data-tip
                    onMouseEnter={showTooltipByProp('address')}
                    onMouseLeave={hideTooltipByProp('address')}
                    id={`directions-street-${uniqueKey}`}
                  >
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButton>
                ) : (
                  <S.DirectionButtonDisabled>
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButtonDisabled>
                )}
                <ToolTip
                  style={{
                    style: {
                      color: '#AD9F86',
                      border: '#8F7E60 1px solid',
                      borderRadius: '8px',
                      padding: '0.5rem',
                    },
                    arrowStyle: {
                      borderColor: '#8F7E60',
                    },
                  }}
                  active={toolTip.address}
                  position="top"
                  arrow="center"
                  parent={`#directions-street-${uniqueKey}`}
                >
                  {address}
                </ToolTip>
              </>
            ) : lat && lng ? (
              <>
                {hide_address === 0 ? (
                  <S.DirectionButton
                    href={`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`}
                    target="_blank"
                    data-tip
                    onMouseEnter={showTooltipByProp('address')}
                    onMouseLeave={hideTooltipByProp('address')}
                    id={`directions-street-${uniqueKey}`}
                  >
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButton>
                ) : (
                  <S.DirectionButtonDisabled>
                    <S.IconLocation src={location} />
                    Directions
                  </S.DirectionButtonDisabled>
                )}
                <ToolTip
                  style={{
                    style: {
                      color: '#AD9F86',
                      border: '#8F7E60 1px solid',
                      borderRadius: '8px',
                      padding: '0.5rem',
                    },
                    arrowStyle: {
                      borderColor: '#8F7E60',
                    },
                  }}
                  active={toolTip.address}
                  position="top"
                  arrow="center"
                  parent={`#directions-street-${uniqueKey}`}
                >
                  Show directions
                </ToolTip>
              </>
            ) : (
              <S.DirectionButtonDisabled>
                <S.IconLocation src={location} />
                Directions
              </S.DirectionButtonDisabled>
            )}
            <S.SeeMoreButton
              onClick={() => {
                goToBussinesDetail(encodeURIComponent(slug2))
              }}
            >
              <S.SeeMore>See More</S.SeeMore>
            </S.SeeMoreButton>
          </S.ButtonSection>
        </S.InfoSection>
        <CarouselContainer>
          <Carousel
            infiniteLoop
            interval={3000}
            showStatus={false}
            showArrows={true}
            showIndicators={false}
            stopOnHover={false}
            emulateTouch
            centerMode
            showThumbs={false}
            autoFocus
            centerSlidePercentage={100}
          >
            {imageUrl && imageUrl.length > 1
              ? imageUrl.map((itemImage: string) => (
                  <div style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
                    <S.PhotoCard src={itemImage} alt="foto" />
                  </div>
                ))
              : null}
          </Carousel>
          {imageUrl && imageUrl.length === 1 ? (
            <div style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
              <S.PhotoCard src={imageUrl[0]} alt="foto" />
            </div>
          ) : null}
          {!imageUrl ? (
            <div style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
              <S.PhotoCard src={defaultImage} alt="foto" />
            </div>
          ) : null}
        </CarouselContainer>
      </S.Card>
    </S.CardContainer>
  )
}

export const getCardHeight = (isMobile: boolean) => {
  return isMobile ? 160 : 230
}
