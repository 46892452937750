import { ServiceResponse } from '@types'
import axios from 'axios'

export const apiServiceDetail = async (id: string): Promise<ServiceResponse> => {
  try {
    const encodedName = encodeURIComponent(id)
    const data = await axios.get(`${process.env.REACT_APP_ENDPOINT_URL}/business/details/${encodedName}`)
    return { ok: true, data }
  } catch (error: any) {
    return { ok: false, message: error.message }
  }
}

// `https://api2.dev.greenbiztracker.org/business/one/${businesId}/${slug}`
